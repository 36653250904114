import React from "react";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import ScrollAnimation from "react-animate-on-scroll";
import {
  ccbiffbamboomRegular,
  mainWhite,
  mainOrange,
  innerWidth,
  montserratRegular,
  playfairdisplayBold,
  darkBlue,
  screen,
  transHover,
} from "../components/common/variables";
import {
  rotateWoman,
  moveMouth,
  moveHand,
} from "../components/common/animations";
import Obfuscate from "react-obfuscate";
import { graphql } from "gatsby";
import SlopedLabel from "../components/SlopedLabel";
import topBg from "../images/about/about-martin-lysicrates-prize-top-background.jpg";
import woman from "../images/about/actors-about-the-martin-lysicrates-prize.png";
import jamesOrange from "../images/about/james-martin-martin-lysicrates-prize.jpg";
import jamesStatue from "../images/about/james-martin-statue.png";
import plainBg from "../images/about/home-background.jpg";
import parkBg from "../images/about/parallax-background-about.jpg";
import monumentStatue from "../images/about/lysicrates-sydney-monument-martin-lysicrates-prize-1.png";
import portrait from "../images/about/pronounce-lyscicrates-martin-lysicrates-prize-no-mouth.png";
import portraitMouth from "../images/about/mouth-cut.png";
import portraitTitle from "../images/about/fun-fact-martin-lysicrates-prize-2.png";
import portraitText from "../images/about/pronounce-lysicrates-martin-lysicrates-prize-2.png";
import resourceBoard from "../images/about/teachers-kit-lysicrates-martin-prize.png";
import resourcePointer from "../images/about/download-now-martin-lysicrates-prize.png";

const Wrapper = styled.div`
  background-image: url(${plainBg});
  background-repeat: repeat-y;
  background-position: center top;
  overflow: hidden;

  .intro {
    padding-top: 60px;
    padding-bottom: 60px;
    background-image: url(${topBg});
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 0px center;
    position: relative;
    overflow: hidden;
    @media ${screen.small} {
      background-size: cover;
      background-position: -140px 0;
      padding-top: 160px;
    }
    @media ${screen.medium} {
      background-position: center;
    }

    &__subcontainer {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding-left: 30px;
      padding-right: 30px;
      @media ${screen.small} {
        padding-left: 50px;
        padding-right: 50px;
      }
    }

    &__title {
      color: ${mainWhite};
      font-family: ${ccbiffbamboomRegular};
      font-size: 3rem;
      @media ${screen.small} {
        font-size: 5rem;
      }

      &--break {
        display: block;
      }

      &--sm {
        font-size: 2rem;
        margin-left: 100px;
        @media ${screen.small} {
          font-size: 4rem;
        }
      }
    }

    &__description {
      max-width: 455px;
      margin-top: 15px;
      position: relative;
      z-index: 2;
      @media ${screen.small} {
        margin-top: 30px;
      }
      @media ${screen.medium} {
        max-width: 690px;
        width: 50%;
      }

      h2 {
        font-family: ${ccbiffbamboomRegular};
        color: ${mainOrange};
        font-size: 1.5rem;
        margin-bottom: 7px;
        max-width: 350px;
        @media ${screen.small} {
          font-size: 2rem;
          margin-bottom: 20px;
        }
        @media ${screen.medium} {
          max-width: inherit;
        }
      }

      p {
        color: ${mainWhite};
        font-family: ${montserratRegular};
        font-weight: 700;
        font-style: italic;
        font-size: 1.05rem;
        max-width: 490px;
        @media ${screen.small} {
          font-size: 1.25rem;
        }
        @media ${screen.large} {
          max-width: 620px;
        }
      }
    }

    &__woman {
      display: none;
      @media ${screen.small} {
        display: block;
        max-width: 400px;
        top: 200px;
        position: absolute;
        right: 165px;
        transform-origin: right bottom;
        pointer-events: none;
        animation: ${rotateWoman} 1s ease-out;
        z-index: 1;
        max-width: 450px;
        top: 130px;
      }
      @media ${screen.small} {
        right: -120px;
        top: 198px;
      }
      @media ${screen.medium} {
        max-width: 510px;
        top: 120px;
        right: 20px;
      }
      @media ${screen.large} {
        max-width: inherit;
        top: 0;
        right: -145px;
      }
      @media ${screen.xlarge} {
        right: 165px;
      }
    }
  }

  .intro-extension {
    margin-top: 20px;
    @media ${screen.large} {
      padding-bottom: 70px;
    }

    &__subcontainer {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding-left: 30px;
      padding-right: 30px;
      @media ${screen.small} {
        padding-left: 50px;
        padding-right: 50px;
      }
    }

    &__description {
      max-width: 490px;

      @media ${screen.medium} {
        width: 50%;
        max-width: 690px;
      }

      p {
        color: ${darkBlue};
        font-family: ${montserratRegular};

        font-size: 1.05rem;
        font-weight: 700;
        font-style: italic;
        @media ${screen.small} {
          font-size: 1.25rem;
        }
      }

      a {
        color: ${mainWhite};
        background: ${mainOrange};
      }
    }
  }

  .james-martin {
    padding: 100px 0 0 0;
    position: relative;
    @media ${screen.large} {
      padding: 100px 0;
    }

    &__subcontainer {
      max-width: ${innerWidth};
      width: 100%;
      margin: 0 auto;
      padding-left: 30px;
      padding-right: 30px;
      @media ${screen.small} {
        display: flex;
        padding-left: 50px;
        padding-right: 50px;
      }
      @media ${screen.medium} {
        justify-content: flex-end;
      }
    }

    &__description {
      max-width: 760px;
      position: relative;
      z-index: 2;
      @media ${screen.small} {
        max-width: 420px;
      }
      @media ${screen.medium} {
        max-width: 690px;
      }
      @media ${screen.large} {
        max-width: 760px;
      }

      .heading {
        color: ${darkBlue};
        font-size: 2rem;
        font-family: ${ccbiffbamboomRegular};
        margin-bottom: 20px;
        @media ${screen.small} {
          font-size: 3.5rem;
        }

        &--break1 {
          display: block;
          margin-left: 70px;
        }
      }

      p {
        color: ${darkBlue};
        font-family: ${montserratRegular};
        font-weight: 700;
        font-style: italic;
        font-size: 1.05rem;
        margin-bottom: 20px;
        @media ${screen.small} {
          font-size: 1.25rem;
        }
        @media ${screen.large} {
          margin-left: 100px;
        }
      }
    }

    &__orange-bg {
      display: none;
      @media ${screen.small} {
        display: block;
        position: absolute;
        max-width: 520px;
        pointer-events: none;
        max-width: 240px;
        top: 200px;
        right: 0;
      }
      @media ${screen.medium} {
        max-width: 160px;
        top: 230px;
        right: auto;
      }
      @media ${screen.large} {
        max-width: 370px;
        top: 150px;
      }
      @media ${screen.xlarge} {
        max-width: 500px;
        top: 150px;
      }
    }

    &__statue {
      position: absolute;
      max-width: 250px;
      left: 70px;
      top: 350px;
      pointer-events: none;
      opacity: 0.25;
      @media ${screen.small} {
        top: 100px;
        left: auto;
        right: 0;
        max-width: calc(100px + 30vw);
        opacity: 1;
      }
      @media ${screen.medium} {
        top: 160px;
        left: 10px;
        max-width: 300px;
      }
      @media ${screen.large} {
        top: 40px;
        left: 5vw;
        max-width: 33vw;
      }
      @media ${screen.xlarge} {
        top: 0;
        left: 200px;
        max-width: 650px;
        border: 0;
      }
    }
  }

  .monument {
    padding-top: 100px;
    @media ${screen.large} {
      padding-top: 300px;
    }

    &__subcontainer {
      max-width: ${innerWidth};
      margin: 0 auto;
      width: 100%;
      display: flex;
      padding-left: 30px;
      padding-right: 30px;
      @media ${screen.small} {
        padding-left: 50px;
        padding-right: 50px;
      }
    }

    &__description {
      padding-bottom: 90px;

      .heading {
        color: ${darkBlue};
        font-size: 2rem;
        font-family: ${ccbiffbamboomRegular};
        margin-left: 25px;
        margin-bottom: 20px;
        @media ${screen.small} {
          font-size: 3.5rem;
          margin-left: 90px;
        }

        &--break1 {
          display: block;
          margin-left: 70px;
        }

        &--break2 {
          display: block;
          margin-left: 110px;
        }
      }

      p {
        font-size: 1.05rem;
        color: ${darkBlue};
        font-family: ${montserratRegular};
        font-weight: 700;
        font-style: italic;
        max-width: 660px;
        margin-bottom: 20px;
        @media ${screen.small} {
          font-size: 1.25rem;
        }
        @media ${screen.large} {
          margin-left: 150px;
        }
      }
    }

    &__park-bg {
      position: relative;
      height: 260px;
      background: url(${parkBg});
      background-position: center top;
      background-size: cover;
      @media ${screen.small} {
        height: 400px;
      }
      @media ${screen.medium} {
        height: 480px;
      }
      @media ${screen.large} {
        background-attachment: fixed;
        height: 600px;
      }

      .statue {
        max-width: 250px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        @media ${screen.small} {
          max-width: 420px;
          right: 0;
          top: -80px;
          transform: translateY(0);
        }
        @media ${screen.medium} {
          max-width: 530px;
          right: 0;
          top: -140px;
        }
        @media ${screen.large} {
          max-width: 720px;
          top: -300px;
          right: 160px;
        }
      }
    }

    &__portrait {
      display: none;
      position: relative;
      height: 600px;
      @media ${screen.small} {
        display: block;
        margin-top: 0;
      }
      @media ${screen.medium} {
        height: 700px;
      }

      .main {
        position: absolute;
        left: -130px;
        top: -45px;
        max-width: 750px;
        pointer-events: none;
      }

      .heading {
        position: absolute;
        top: -65px;
        left: 310px;
        pointer-events: none;
      }

      .message {
        position: absolute;
        left: 340px;
        top: -75px;
        pointer-events: none;
      }

      .mouth {
        animation: ${moveMouth} 5s ease-in 0s infinite both;
        position: absolute;
        left: 255px;
        top: 265px;
        pointer-events: none;
      }
    }
  }

  .get-involved {
    position: relative;
    padding-bottom: 70px;
    padding-top: 90px;
    @media ${screen.small} {
      padding-bottom: 200px;
    }
    @media ${screen.medium} {
      margin-top: -50px;
    }
    @media ${screen.large} {
      margin-top: -200px;
    }

    &__subcontainer {
      max-width: ${innerWidth};
      margin: 0 auto;
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      flex-direction: column;
      @media ${screen.small} {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-left: 50px;
        padding-right: 50px;
      }
      @media ${screen.medium} {
        flex-wrap: nowrap;
      }
    }

    &__description {
      max-width: 760px;

      .heading {
        color: ${darkBlue};
        font-size: 2rem;
        font-family: ${ccbiffbamboomRegular};
        margin-bottom: 20px;
        margin-left: 25px;
        @media ${screen.small} {
          font-size: 3.5rem;
          margin-left: 0;
        }

        &--break1 {
          display: block;
          margin-left: 70px;
        }

        &--break2 {
          display: block;
          margin-left: 110px;
        }
      }

      p {
        font-size: 1.05rem;
        color: ${darkBlue};
        font-family: ${montserratRegular};
        font-weight: 700;
        font-style: italic;
        margin-bottom: 20px;
        @media ${screen.small} {
          font-size: 1.25rem;
        }
        @media ${screen.large} {
          margin-left: 70px;
        }
      }

      .cta {
        margin-top: 40px;
        font-size: 1.25rem;
        @media ${screen.small} {
          font-size: 1.45rem;
          margin-top: 60px;
        }
        a {
          display: inline-block;
          margin-top: 20px;
          color: ${mainWhite};
          background: ${mainOrange};
          transition: ${transHover};
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainOrange};
              background: none;
            }
          }
        }
      }
    }

    &__resources {
      max-width: 800px;
      align-self: flex-start;
      order: 1;
      @media ${screen.medium} {
        order: 0;
        align-self: center;
      }
      @media ${screen.large} {
        align-self: flex-start;
      }

      .board {
        position: relative;

        img {
          max-width: 800px;
          width: 100%;
        }

        .texts {
          color: ${mainWhite};
          text-align: center;
          position: absolute;
          top: calc(50% - 28px);
          left: calc(50% - 4px);
          transform: translate(-50%, -50%);
          font-family: ${playfairdisplayBold};
          font-size: 0.65rem;
          @media ${screen.small} {
            font-size: 1.2rem;
            top: calc(50% - 55px);
            left: calc(50% - 10px);
          }
          @media ${screen.medium} {
            font-size: 1rem;
            top: calc(50% - 49px);
          }
          @media ${screen.large} {
            font-size: 1.2rem;
            top: calc(50% - 55px);
          }

          &--break1 {
            display: block;
            margin-bottom: 5px;
            @media ${screen.small} {
              margin-bottom: 12px;
            }
          }
          &--break2 {
            display: block;
            margin-top: 5px;
            @media ${screen.small} {
              margin-top: 12px;
            }
          }
          &--lg {
            font-size: 0.7rem;
            @media ${screen.small} {
              font-size: 2rem;
            }
            @media ${screen.medium} {
              font-size: 1.35rem;
            }
            @media ${screen.large} {
              font-size: 2rem;
            }
          }
        }

        .hand {
          position: absolute;
          top: calc(50% - 50px);
          right: 0;
          transform: translateY(-50%);
          @media ${screen.medium} {
            right: 17px;
          }
          @media ${screen.large} {
            right: 20px;
          }

          img {
            max-width: 64px;
            animation: ${moveHand} 2.5s linear infinite both;
            @media ${screen.small} {
              max-width: 130px;
            }
            @media ${screen.medium} {
              max-width: 88px;
            }
            @media ${screen.large} {
              max-width: 120px;
            }
          }
        }
      }
    }
  }
`;

const AboutPage = ({ data }) => {
  const subheading = data.content.data.subheading;
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : "About";
  const metaDescription = data.content.data.meta_description;

  const mainDescription = data.content.data.main_description.html;
  const secondaryDescription = data.content.data.secondary_description.html;
  const jamesDescription = data.content.data.james_description.html;
  const monumentDescription = data.content.data.monument_description.html;
  const getInvolvedDescription =
    data.content.data.get_involved_description.html;
  const kitUrl = data.content.data.downloadable_kit.url;

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <div className="intro">
          <div className="intro__subcontainer">
            <h1 className="intro__title">
              <span className="intro__title--break">THEATRE</span>
              <span className="intro__title--sm">FOR</span> ALL!
            </h1>
            <div className="intro__description">
              <h2>{subheading}</h2>
              <div dangerouslySetInnerHTML={{ __html: mainDescription }} />
            </div>
          </div>
          <img
            className="intro__woman"
            src={woman}
            alt="Reading Martin Lysicrates"
          />
        </div>

        <div className="intro-extension">
          <div className="intro-extension__subcontainer">
            <div
              className="intro-extension__description"
              dangerouslySetInnerHTML={{ __html: secondaryDescription }}
            />
          </div>
        </div>

        <div className="james-martin">
          <img
            className="james-martin__orange-bg"
            src={jamesOrange}
            alt="James Martin Painting"
          />
          <img
            className="james-martin__statue"
            src={jamesStatue}
            alt="James Martin Statue"
          />
          <div className="james-martin__subcontainer">
            <div className="james-martin__description">
              <h2 className="heading">
                <SlopedLabel text="WHO IS" />
                <span className="heading--break1">JAMES MARTIN?</span>
              </h2>
              <div dangerouslySetInnerHTML={{ __html: jamesDescription }} />
            </div>
          </div>
        </div>

        <div className="monument">
          <div className="monument__subcontainer">
            <div className="monument__description">
              <h2 className="heading">
                <SlopedLabel text="THE STORY OF THE" />
                <span className="heading--break1">LYSICRATES MONUMENT</span>
              </h2>
              <div dangerouslySetInnerHTML={{ __html: monumentDescription }} />
            </div>
          </div>
          <ScrollAnimation
            className="monument__park-bg"
            animateIn="fadeInUp"
            animateOnce={true}
          >
            <img
              className="statue"
              src={monumentStatue}
              alt="Lysicrates Monument"
            />
          </ScrollAnimation>
          <ScrollAnimation
            className="monument__portrait"
            animateIn="fadeInLeft"
            animateOnce={true}
          >
            <img className="main" src={portrait} alt="James Martin" />
            <img className="mouth" src={portraitMouth} alt="James Martin" />
            <img className="heading" src={portraitTitle} alt="James Martin" />
            <img className="message" src={portraitText} alt="James Martin" />
          </ScrollAnimation>
        </div>

        <div className="get-involved">
          <div className="get-involved__subcontainer">
            <div className="get-involved__resources">
              <ScrollAnimation
                className="board"
                animateIn="fadeInUp"
                animateOnce={true}
              >
                <a href={kitUrl} target="_blank" rel="noopener noreferrer">
                  <img src={resourceBoard} alt="Download" />{" "}
                </a>

                <a href={kitUrl} target="_blank" rel="noopener noreferrer">
                  <h6 className="texts">
                    <span className="texts--break1">Download the</span>
                    <span className="texts--lg">Martin-Lysicrates</span>
                    <span className="texts--break2">Classroom Theatre Kit</span>
                  </h6>
                </a>
                <div className="hand">
                  <img src={resourcePointer} alt="Pointer" />
                </div>
              </ScrollAnimation>
            </div>
            <div className="get-involved__description">
              <h2 className="heading">
                <SlopedLabel text="LOVE THEATRE?" />
                <span className="heading--break1">GET INVOLVED!</span>
              </h2>
              <div
                dangerouslySetInnerHTML={{ __html: getInvolvedDescription }}
              />
              <p className="cta">
                Like to know more about getting involved with theatre? Get in
                touch
                <br />
                <Obfuscate email={`hello@martinlysicrates.com.au`} />
              </p>
            </div>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default AboutPage;

export const dataQuery = graphql`
  {
    content: prismicAboutPage {
      data {
        subheading
        title_tag
        meta_description
        main_description {
          html
        }
        secondary_description {
          html
        }
        james_description {
          html
        }
        monument_description {
          html
        }
        get_involved_description {
          html
        }
        downloadable_kit {
          url
        }
      }
    }
  }
`;
