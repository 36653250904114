import { keyframes } from "styled-components";

export const rotateWoman = keyframes`
  0% {
    opacity: 0.5;
    transform: rotate(-90deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0);
  }
`;

export const moveMouth = keyframes`
  0% {
    transform: translateY(3px);
  }
  73% {
    transform: translateY(0px);
  }
  76% {
    transform: translateY(6px);
  }
  79% {
    transform: translateY(0px);
  }
  82% {
    transform: translateY(6px);
  }
  85% {
    transform: translateY(0px);
  }
  88% {
    transform: translateY(6px);
  }
  91% {
    transform: translateY(0px);
  }
  93% {
    transform: translateY(6px);
  }
  98% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(3px);
  }
`;

export const moveHand = keyframes`
   0% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(0);
  }
  55% {
    transform: translateX(-10px);
  }
  70% {
    transform: translateX(0);
  }
  85% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
`;
